import { Attributes, List, LookupBase, Rules } from '@singularsystems/neo-core';
import SurveyParticipantDocumentLookup from './SurveyParticipantDocumentLookup';

export default class SurveyLookup extends LookupBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public readonly surveyId: number = 0;

    public readonly surveyParticipantId: number = 0;

    public readonly surveyName: string = "";

    public readonly surveyTypeId: number = 0;

    public readonly surveyTypeName: string = "";

    @Attributes.Nullable()
    public readonly incentiveSchemeId: number | null = null;

    public readonly incentiveSchemeName: string = "";

    @Attributes.Nullable()
    public readonly awardPrepId: number | null = null;

    public readonly awardNumbers: string = "";

    public readonly awardDisplay: string = "";

    @Attributes.Date()
    public readonly releaseDate: Date | null = null;

    @Attributes.Date()
    public readonly endDate: Date | null = null;

    public readonly awardDocumentSetId: number = 0;

    @Attributes.Date()
    public readonly publishedOn: Date | null = null;

    // Client only properties / methods
}