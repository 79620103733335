import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import SurveyCompletionVM, { SurveyViewMode } from './SurveyCompletionVM';
import { observer } from 'mobx-react';
import Home from '../../../../App/Views/Home';
import AwardDocumentComponent from '../Acceptance/Components/Steps/Documents/AwardDocumentComponent';
import { Components } from '@singularsystems/neo-core';
import { AwardDocumentAcceptanceType } from '../../../Common/Models/AwardDocuments/AwardDocumentAcceptanceType.enum';

class SurveyCompletionParams {
    public survey = {};
}

@observer
export default class SurveyCompletionView extends Views.ViewBase<SurveyCompletionVM, SurveyCompletionParams> {
   public static params = new SurveyCompletionParams();

    constructor(props: unknown) {
        super("Survey Completion", SurveyCompletionVM, props);
    }

    public async viewParamsUpdated() {
		if (this.viewParams.survey.value) {
			const surveyParticipantId = this.viewParams.survey.asNullableInt();
			if (surveyParticipantId ) {
				await this.viewModel.loadSurveyData(surveyParticipantId);
			}
		}
	}

    private back() {
        this.viewModel.back();
    }

    public render() {
        return (
            <div>
                <div className="my-award-document">
                <div className="award-document-header page-block-alt p-3">
                    <Neo.GridLayout md={2}>
                        <h2>{this.viewModel.surveyParticipantDocumentLookup.documentName}</h2>
                    </Neo.GridLayout>
                </div>
            </div>

                {this.viewModel.surveyParticipantDocumentLookup &&
                    <AwardDocumentComponent
                        viewModel={this.viewModel.documentComponentVM}
                        document={this.viewModel.surveyParticipantDocumentLookup}
                        mainTaskRunner={this.viewModel.taskRunner}
                        readOnly={false}
                        documentCompleted={() => { }}
                        hideHeader
                        alignAcceptTsAndCsLeft
                        isAcceptedAwardSummary={false} />} 

            <div className="accept-awards">
                <div className="accept-awards-footer page-block-alt">
                    <div className="accept-buttons">
                        <div>
                            {this.viewModel.viewMode !== SurveyViewMode.Survey &&
                            <Neo.Button variant="secondary" onClick={() => this.back()}>Back</Neo.Button>}
                        </div>

                        <div className="right-tools">
                            {this.getActionButtons()}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getActionButtons() {
        return this.getActionButtonOptions().map((options, index) => <Neo.Button key={`button-${index}-${options.variant}`} {...options} />)
    }

    private getActionButtonOptions(): Components.IButtonOptions[] {
        let buttons: Components.IButtonOptions[] = [];

        if (this.viewModel.viewMode === SurveyViewMode.Survey) {
            const documentsLoaded = this.viewModel.surveyParticipantLookup.documents.length > 0;
            if (documentsLoaded) {
                buttons.push({
                    variant: "primary",
                    text: "View Documents",
                    onClick: () => this.viewModel.next(),
                });
            }
            else
            {
                buttons.push({
                    variant: "primary",
                    text: "Complete",
                    onClick: () => {
                        this.completeSurvey();
                    },
                });
            }
        }

        else if (this.viewModel.viewMode === SurveyViewMode.Document) {
            if (this.viewModel.isLastDocument) {
                buttons.push({
                    variant: "primary",
                    text: "Complete",
                    disabled: (!this.viewModel.documentComponentVM.document.acceptedTermsAndConditions) && this.viewModel.documentComponentVM.document.acceptanceType === AwardDocumentAcceptanceType.AcceptTermsAndConditions,
                    onClick: () => {
                        this.completeSurvey();
                    },
                });
            }
            else {
                buttons.push({
                    variant: "primary",
                    text: "Next Document",
                    disabled: (!this.viewModel.documentComponentVM.document.acceptedTermsAndConditions) && this.viewModel.documentComponentVM.document.acceptanceType === AwardDocumentAcceptanceType.AcceptTermsAndConditions,
                    onClick: () => this.viewModel.next(),
                });

            }       
        }
        return buttons;
    }

    private completeSurvey() {
        this.viewModel.completeSurvey();
        this.viewModel.dispose();
        this.navigation.navigateToView(Home);
    }
}