import { Attributes, ModelBase, Validation } from '@singularsystems/neo-core';
import FileDescriptor from '../../Files/FileDescriptor';

export default class RespondToParticipantOfferAwardDocumentCommand extends ModelBase {

    constructor() {
        super();
        this.makeObservable();
    }

    public participantOfferAwardDocumentId: number = 0;

    public surveyParticipantDocumentId: number = 0;

    public acceptedTermsAndConditions: boolean | null = null;

    @Attributes.ChildObject(FileDescriptor)
    public uploadedFileDescriptor: FileDescriptor | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New respond to participant offer award document command";
        } else {
            return "Respond To Participant Offer Award Document Command";
        }
    }
}