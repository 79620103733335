import { Attributes, ModelBase, Validation } from '@singularsystems/neo-core';
import { AwardDocumentAcceptanceType } from '../../AwardDocuments/AwardDocumentAcceptanceType.enum';
import { AwardDocumentDeliveryType } from '../../AwardDocuments/AwardDocumentDeliveryType.enum';
import FileDescriptor from '../../Files/FileDescriptor';

export default class SurveyParticipantDocumentLookup extends ModelBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public surveyParticipantDocumentId: number = 0;

    public readonly surveyId: number = 0;

    public surveyParticipantId: number = 0;

    public awardDocumentId: number = 0;

    public readonly awardDocumentSetId: number = 0;

    public readonly awardDocumentSetHtml: string = "";

    @Attributes.Date()
    public readonly documentDate: Date = new Date();

    public documentName: string = "";

    @Attributes.Integer()
    public readonly orderNum: number = 0;

    @Attributes.ChildObject(FileDescriptor)
    public downloadFileDescriptor: FileDescriptor | null = null;

    public readonly awardDocumentType: string = "";
    
    public deliveryType: AwardDocumentDeliveryType | null = null;

    public acceptanceType: AwardDocumentAcceptanceType | null = null;

    public acceptedTermsAndConditions: boolean | null = null;

    public uploadedFileName: string = "";

    @Attributes.Date()
    public respondedOn: Date | null = null;

    @Attributes.Date()
    public readonly firstViewedOn: Date | null = null;

    @Attributes.Date()
    public readonly completedOn: Date | null = null;

    // Client only properties / methods

    public incentiveSchemeId: number = 0;
    public incentivePlanId: number = 0;
    public incentiveSchemeDocumentId: number = 0;
    public participantOfferAwardDocumentSetId: number = 0;
    public participantOfferId: number = 0;
    public awardDocumentHTML: string = "";

    public get isDownloadOnly () {
        return false;
    }

    public isCompleted() {
        return this.acceptanceType === AwardDocumentAcceptanceType.None || this.respondedOn !== null;
    }
    
    public hasResponded() {
        return this.respondedOn !== null;
    }

    public headerClass() {
        //base
        let className: string = "award-document-header";

        // has responded or not
        className += (this.hasResponded() ? " responded" : "");

        return className;
    }

    public getIcon() {
        return SurveyParticipantDocumentLookup.getDocumentIcon(this.deliveryType) + " text-primary"
    }

    public static getDocumentIcon(deliveryType: AwardDocumentDeliveryType | null) {
        switch (deliveryType) {
            case AwardDocumentDeliveryType.HtmlTemplate: return "fa-file-alt";
            case AwardDocumentDeliveryType.DocumentDownload: return "fa-file-download";
        }

        return "file";
    }

    @Attributes.NoTracking()
    public isExpanded: boolean = false;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}