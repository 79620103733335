import { Attributes, List, LookupBase } from '@singularsystems/neo-core';
import SurveyParticipantDocumentLookup from './SurveyParticipantDocumentLookup';

export default class SurveyParticipantLookup extends LookupBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public readonly surveyParticipantId: number = 0;

    public readonly surveyName: string = "";

    public readonly surveyId: number = 0;

    @Attributes.Nullable()
    public readonly questionnaireAnsweredId: number | null = null;

    @Attributes.Date()
    public readonly createdOn: Date = new Date();

    @Attributes.Date()
    public readonly firstViewedOn: Date | null = null;

    @Attributes.Date()
    public readonly completedOn: Date | null = null;

    public readonly awardDocumentSetHtml: string = "";

    public readonly documents = new List(SurveyParticipantDocumentLookup);

    // Client only properties / methods
}