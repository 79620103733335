import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, AppTypes } from 'st-app/Services/AppService';
import SurveyTypeLookup from '../Models/Surveys/Queries/SurveyTypeLookup';
import SurveyTypeLookupCriteria from '../Models/Surveys/Queries/SurveyTypeLookupCriteria';
import SurveyType from '../Models/Surveys/SurveyType';
import SurveyLookupCriteria from '../Models/Surveys/Queries/SurveyLookupCriteria';
import SurveyLookup from '../Models/Surveys/Queries/SurveyLookup';
import UpsertSurveyCommand from '../Models/Surveys/Commands/UpsertSurveyCommand';
import SurveyParticipantDocumentLookup from '../Models/Surveys/Queries/SurveyParticipantDocumentLookup';
import SurveyParticipantLookup from '../Models/Surveys/Queries/SurveyParticipantLookup';

export interface ISurveysApiClient {

    /** 
     * Gets all survey types
     * @returns A list of survey types
     */
    getAllSurveyTypes(): AxiosPromise<Array<Model.PlainObject<SurveyTypeLookup>>>;

    /** 
     * Gets the survey types
     * @param request The page request
     * @returns A paged list of survey types
     */
    getSurveyTypes(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SurveyTypeLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainTrackedObject<SurveyType>>>;

    /** 
     * Saves the survey types
     * @param surveyTypes The survey types
     */
    saveSurveyTypes(surveyTypes: Array<Model.PartialPlainObject<SurveyType>>): AxiosPromise;

    /** 
     * Gets all surveys
     * @returns A list of surveys
     */
    getAllSurveys(): AxiosPromise<Array<Model.PlainObject<SurveyLookup>>>;

    /** 
     * Gets the surveys
     * @param request The page request
     * @returns A paged list of surveys
     */
    getSurveys(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SurveyLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<SurveyLookup>>>;

    /** 
     * Saves a survey
     * @param upsertSurveyCommand The upsert survey command
     */
    saveSurvey(upsertSurveyCommand: Model.PartialPlainObject<UpsertSurveyCommand>): AxiosPromise;

    /** 
     * Deletes a survey   
     * @param surveyId The survey id
     */
    deleteSurvey(surveyId: number): AxiosPromise;

    /** 
     * Publishes a survey
     * @param surveyId The survey id
     */
    publishSurvey(surveyId: number): AxiosPromise;

    /** 
     * Gets the list of active surveys for a participant
     */
    getActiveSurveysForParticipant(): AxiosPromise<Array<Model.PlainObject<SurveyLookup>>>;

    /** 
     * Gets the list of survey documents
     */
    getSurveyParticipantDocumentLookup(surveyParticipantId: number, surveyParticipantDocumentId: number): AxiosPromise<Model.PlainObject<SurveyParticipantDocumentLookup>>;

    /** 
     * Updates the survey participant dates
     * @param surveyParticipantId The survey participant id
     * @param isFirstViewed Indicates if it is the first viewed date
     * @param completed Indicates if the survey is completed
     */
    updateSurveyParticipantDates(surveyParticipantId: number, isFirstViewed: boolean, completed: boolean): AxiosPromise;

    /** 
     * Gets the survey participant lookup with documents
     * @param surveyParticipantId The survey participant id
     */
    getSurveyParticipantsLookupAsync(surveyParticipantId: number): AxiosPromise<Model.PlainObject<SurveyParticipantLookup>>;

    // Client only properties / methods
}

@injectable()
export default class SurveysApiClient extends Data.ApiClientBase implements ISurveysApiClient {

    constructor(config = AppService.get(AppTypes.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/Surveys`);
    }

    public getAllSurveyTypes(): AxiosPromise<Array<Model.PlainObject<SurveyTypeLookup>>> {
        return this.axios.get(`${this.apiPath}/all-survey-types`);
    }

    public getSurveyTypes(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SurveyTypeLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainTrackedObject<SurveyType>>> {
        return this.axios.get(`${this.apiPath}/survey-types?${Utils.getQueryString(request)}`);
    }

    public saveSurveyTypes(surveyTypes: Array<Model.PartialPlainObject<SurveyType>>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/save-survey-types`, surveyTypes);
    }

    public getAllSurveys(): AxiosPromise<Array<Model.PlainObject<SurveyLookup>>> {
        return this.axios.get(`${this.apiPath}/all-surveys`);
    }

    public getSurveys(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SurveyLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<SurveyLookup>>> {
        return this.axios.get(`${this.apiPath}/surveys?${Utils.getQueryString(request)}`);
    }

    public saveSurvey(upsertSurveyCommand: Model.PartialPlainObject<UpsertSurveyCommand>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/save-survey`, upsertSurveyCommand);
    }

    public deleteSurvey(surveyId: number): AxiosPromise {
        return this.axios.delete(`${this.apiPath}/delete-survey/${surveyId}?surveyId=${surveyId}`);
    }

    public publishSurvey(surveyId: number): AxiosPromise {
        return this.axios.post(`${this.apiPath}/publish-survey/${surveyId}?surveyId=${surveyId}`);
    }

    public getActiveSurveysForParticipant(): AxiosPromise<Array<Model.PlainObject<SurveyLookup>>> {
        return this.axios.post(`${this.apiPath}/active-surveys`);
    }

    public getSurveyParticipantDocumentLookup(surveyParticipantId: number, surveyParticipantDocumentId: number): AxiosPromise<Model.PlainObject<SurveyParticipantDocumentLookup>> {
        return this.axios.post(`${this.apiPath}/documents?surveyParticipantId=${surveyParticipantId}&surveyParticipantDocumentId=${surveyParticipantDocumentId}`);
    }

    public updateSurveyParticipantDates(surveyParticipantId: number, isFirstViewed: boolean, completed: boolean): AxiosPromise {
        return this.axios.post(`${this.apiPath}/dates?surveyParticipantId=${surveyParticipantId}&isFirstViewed=${isFirstViewed}&completed=${completed}`);
    }

    public getSurveyParticipantsLookupAsync(surveyParticipantId: number): AxiosPromise<Model.PlainObject<SurveyParticipantLookup>> {
        return this.axios.post(`${this.apiPath}/survey-participants-documents?surveyParticipantId=${surveyParticipantId}`);
    }

    // Client only properties / methods
}