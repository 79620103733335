import * as React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { AppService, AppTypes } from '../../App/Services/AppService';
import { DateUtils, List, NeoModel, NumberUtils } from '@singularsystems/neo-core';
import { Awards, Clients, Transactions } from '../'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import { StyleUtils } from '../Services/StyleUtils';
import { PageBlock } from '../Components/Application/PageBlock';
import InfoIcon from '../Components/Application/InfoIcon';
import { InstrumentType } from '../../Awards/Common/Models/Enums/InstrumentType';
import { HtmlView, NotificationServiceTypes } from '@singularsystems/neo-notifications';
import { TemplateTypes } from '../../Clients/Participants/Models/TemplateTypes';
import { Types as CommonAwardTypes } from '../../Awards/Common/CommonAwardsTypes';
import SurveyLookup from '../../Awards/Common/Models/Surveys/Queries/SurveyLookup';

@NeoModel
class HomeVM extends Views.ViewModelBase {
    public constructor(
        taskRunner = AppService.get(AppTypes.Neo.TaskRunner),
        public myAwardsTaskRunner = AppService.get(AppTypes.Neo.TaskRunner),
        public approvalsTaskRunner = AppService.get(AppTypes.Neo.TaskRunner),
        public portfolioTaskRunner = AppService.get(AppTypes.Neo.TaskRunner),
        public documentsTaskRunner = AppService.get(AppTypes.Neo.TaskRunner),
        public surveysTaskRunner = AppService.get(AppTypes.Neo.TaskRunner),
        private myAwardsQueryClient = AppService.get(AppTypes.Awards.ApiClients.MyAwardsApiClient),
        private approvalsQueryClient = AppService.get(AppTypes.Awards.ApiClients.ApprovalsQueryApiClient),
        public authService = AppService.get(AppTypes.Shared.Services.STAuthenticationService),
        public portfolioService = AppService.get(AppTypes.Transactions.Services.PortfolioService),
        private tenantDocumentsApiClient = AppService.get(AppTypes.Clients.ApiClients.TenantDocumentsQueryApiClient),
        private templatesApiClient = AppService.get(NotificationServiceTypes.ApiClients.TemplateProcessing),
        public appNoticesTaskRunner = AppService.get(AppTypes.Neo.TaskRunner),
        private surveysApiClient = AppService.get(CommonAwardTypes.ApiClients.SurveysApiClient),
    ){
        super(taskRunner);
    }

    public myAwardInfo = new Awards.MyAwardInfoLookup();
    public nextAwardToReleaseDate: Date | null = null;
    public welcomeDocumentList = new List(Clients.TenantDocumentLookup);
    public participantAppNotice: string = "";
    public closedPeriodNotice: string = "";
    public surveyInfo = new List(SurveyLookup);
    public nextSurveyEndDate: Date | null = null;

    public async initialise() {

        this.portfolioService.ensureLoaded(this.portfolioTaskRunner);

        this.loadMyAwardsInfo();
        this.loadApprovalsInfo();
        this.loadDocuments();
        this.loadSurveys();

        const [templateResultGeneralNotice, templateResultClosedPeriod] = await this.appNoticesTaskRunner.waitFor(Promise.all([
            this.templatesApiClient.merge(TemplateTypes.ParticipantAppNotice, "", "", {}),
            this.templatesApiClient.merge(TemplateTypes.ClosedPeriodNotice, "", "", {})
          ]));
        this.participantAppNotice = templateResultGeneralNotice.mergedText.body;
        this.closedPeriodNotice = templateResultClosedPeriod.mergedText.body;
    }

    public get hasGeneralNotice() {
        return this.participantAppNotice.length > 20;
      }

    public getActionDateClass(actionDate: Date) {
        const now = new Date();
        if (actionDate <= now) {
            // today or the past, return danger.
            return "text-primary font-weight-bold";
        }
        else {
            // see how far into the future.
            const days = DateUtils.difference(now, actionDate).days;

            if (days >= 3) {
                return "text-primary";
            } else {
                return "text-primary font-italic";
            }
        }
    }

    private loadMyAwardsInfo() {
        this.myAwardsTaskRunner.run(async () => {
            const result = await this.myAwardsQueryClient.getPendingAwardInfo();
            this.myAwardInfo.set(result.data);
        });
    }

    private loadApprovalsInfo() {
        this.approvalsTaskRunner.run(async () => {
            const result = await this.approvalsQueryClient.participantNextAwardReleaseDate();
            if (result.data) {
                this.nextAwardToReleaseDate = new Date(result.data);
            } else {
                this.nextAwardToReleaseDate = null;
            }
        });
    }

    private async loadDocuments() {
        const result = await this.documentsTaskRunner.waitFor(this.tenantDocumentsApiClient.getTenantDocumentLookups(Clients.DocumentDisplayLocation.ParticipantDashboardWelcome));
        this.welcomeDocumentList.set(result.data);
    }

    private async loadSurveys() {
        await this.surveysTaskRunner.run(async () => {
            const result = await this.surveysApiClient.getActiveSurveysForParticipant();
            this.surveyInfo.set(result.data);

            if (this.surveyInfo.length > 0) {
                const endDates = this.surveyInfo
                    .map(s => s.endDate)
                    .filter(date => date !== null)
                    .map(date => date!.getTime());

                if (endDates.length > 0) {
                    const nextAcceptanceActionBy = Math.min(...endDates);
                    this.nextSurveyEndDate = new Date(nextAcceptanceActionBy);
                }
            }
        });
    }

    public async viewTenantDocument(doc: Clients.TenantDocumentLookup) {

        if (doc.fileDescriptorId) {
            const result = await this.documentsTaskRunner.waitFor(this.tenantDocumentsApiClient.getDocumentDownloadUrl(doc.fileDescriptorId, true));
            window.open(result.data, "_blank");
        } else {
            window.open(doc.documentUrl, "_blank");
        }
    }
}

@observer
export default class Home extends Views.ViewBase<HomeVM> {

    constructor(props: unknown) {
        super("", HomeVM, props);
    }

    public render() {
        const appData = this.viewModel.portfolioService.appData;
        const upcomingExpiries = this.viewModel.portfolioService.appData?.trancheBalances.filter(tb => tb.hasUpcomingExpiry).groupBy(tb => tb.awardId, (key, tb) => tb);

        const chartOptions = this.getChartOptions();
        const user = this.viewModel.authService.user!;
        const closedPeriods = appData?.closedPeriods

        let isAlt = false;

        return (
            <div>
                <PageBlock alt={isAlt} className="pt-3">
                    <div>
                        <h2>Welcome!</h2>
                        <p>
                            Hello {user.displayName}{user.proxyParticipant ? ` (as ${user.proxyParticipant})` : ""}. Welcome to the awards portal where you can view, accept and trade your awards.
                        </p>
                        <Neo.Loader task={this.viewModel.documentsTaskRunner}>
                            <div className="pt-3">
                                {this.viewModel.welcomeDocumentList.map(doc => (
                                    <Neo.Button className="btn-150 mr-2" onClick={() => this.viewModel.viewTenantDocument(doc)}>{doc.displayName}</Neo.Button>
                                ))}
                            </div>
                        </Neo.Loader>
                    </div>
                    <div>
                        <h2>Today's share price</h2>
                        {this.viewModel.portfolioService.appData &&
                            <table>
                                <tbody>
                                    {this.viewModel.portfolioService.appData.ownedInstruments.filter(c => c.price !== null && c.instrumentType !== InstrumentType.Cash).map(i => (
                                        <tr key={i.entityIdentifier}>
                                            <td className="text-heading">{i.instrumentName}</td>
                                            <td className="text-heading pl-2">{i.instrumentCode}</td>
                                            <td className="text-right pl-4">{NumberUtils.format(i.price!, `${i.currencySymbol} #,##0.00######;(${i.currencySymbol}#,##0.00######)`)}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </table>}
                    </div>
                { isAlt = !isAlt }
                </PageBlock>
               
                {((closedPeriods && closedPeriods.length > 0) || this.viewModel.hasGeneralNotice) &&
                <PageBlock alt={isAlt} className='notice-container'>
                   {this.viewModel.hasGeneralNotice &&
                        <div>
                            <div className="general-notice">
                                <h2 className="general-notice-heading">General Notice</h2>
                                 <HtmlView html={this.viewModel.participantAppNotice} />
                            </div>
                        </div>
                    }
                    <Neo.Loader task={this.viewModel.appNoticesTaskRunner}>
                        {closedPeriods && closedPeriods.length > 0 &&
                            <div className={`closed-period-notice ${!this.viewModel.hasGeneralNotice ? 'full-width' : ''}`}>
                                    <h2 className="closed-period-heading">Closed Period Notice</h2>
                                        <HtmlView html={this.viewModel.closedPeriodNotice} />
                                            <p className ="closed-period-message">
                                                {closedPeriods.map((period,index)=>(
                                                    <p key ={index}>
                                                        <>
                                                            <li>{period.startDate == null && period.endDate != null ? (
                                                                `${period.closedPeriodName} until ${period.endDate?.toDateString()}` //
                                                                ) : period.endDate == null && period.startDate != null ? (
                                                                `${period.closedPeriodName}  ${period.startDate?.toDateString()}`//
                                                                ) : period.startDate == null && period.endDate== null ? (
                                                                    `${period.closedPeriodName} `
                                                                ) : (
                                                                    `${period.closedPeriodName}  ${period.startDate?.toDateString()} until ${period.endDate?.toDateString()}`
                                                                )}
                                                            </li>
                                                        </>
                                                    </p>
                                                ))}
                                            </p>
                            </div>
                        }
                    </Neo.Loader>
                { isAlt = !isAlt }
                </PageBlock>}
                { this.viewModel.surveyInfo.length > 0 &&
                <PageBlock alt={isAlt}>
                    <Neo.Loader task={this.viewModel.surveysTaskRunner}>
                            <h2>My Tasks</h2>
                            {this.viewModel.nextSurveyEndDate && <span className={this.viewModel.getActionDateClass(this.viewModel.nextSurveyEndDate)}>
                                <i className="far fa-bell mr-2" />ACTION NEEDED: Task requires completion before {DateUtils.format(this.viewModel.nextSurveyEndDate, "dddd dd MMMM yyyy")}.
                            </span>}
                                {this.viewModel.surveyInfo.map(survey => (
                                <Neo.Button key={survey.surveyId} onClick={() => this.navigation.navigateToView(Awards.SurveyCompletionView, { survey: survey.surveyParticipantId })} className="mr-3 mt-3 btn-150">
                                    {survey.surveyName}
                                </Neo.Button>
                                ))}


                    </Neo.Loader>
                    { isAlt = !isAlt }
                </PageBlock>
                }
                <PageBlock alt={isAlt}>
                    <Neo.Loader task={this.viewModel.myAwardsTaskRunner}>
                        <div>
                            <h2>{this.viewModel.myAwardInfo.myAwardsHeader}</h2>

                            <div>
                                <p>
                                    {this.viewModel.myAwardInfo.nextAcceptanceActionBy &&
                                        <span className={this.viewModel.getActionDateClass(this.viewModel.myAwardInfo.nextAcceptanceActionBy)}>
                                            <i className="far fa-bell mr-2" />ACTION NEEDED: Acceptance awards by {DateUtils.format(this.viewModel.myAwardInfo.nextAcceptanceActionBy, "dddd dd MMMM yyyy")}.
                                        </span>}
                                    <span className="d-block mt-1">View awards that have been allocated to you{this.viewModel.myAwardInfo.nextAcceptanceActionBy ? " and action any awards that require your acceptance." : "."}</span>
                                </p>
                                <div className="mt-3">
                                    {this.viewModel.myAwardInfo.nextAcceptanceActionBy &&
                                        <Neo.Button className="btn-150" onClick={() => this.navigation.navigateToView(Awards.AcceptAwardsView)}>View Awards</Neo.Button>
                                    }
                                    {!this.viewModel.myAwardInfo.nextAcceptanceActionBy &&
                                        <Neo.Button className="btn-150" onClick={() => this.navigation.navigateToView(Awards.MyAwardsView)}>View Awards</Neo.Button>}
                                </div>
                            </div>
                        </div>
                    </Neo.Loader>
                    <Neo.Loader task={this.viewModel.approvalsTaskRunner}>
                        <div>
                            <h2>Awards Release</h2>

                            {this.viewModel.nextAwardToReleaseDate &&
                                <div>
                                    <p>
                                        <span className={this.viewModel.getActionDateClass(this.viewModel.nextAwardToReleaseDate)}>
                                            <i className="far fa-bell mr-2" />ACTION NEEDED: Awards due for release by {DateUtils.format(this.viewModel.nextAwardToReleaseDate, "dddd dd MMMM yyyy")}.
                                        </span>
                                        <span className="d-block mt-1">View awards that are ready to be released</span>
                                    </p>
                                    <div className="mt-3">
                                        <Neo.Button className="btn-150" onClick={() => this.navigation.navigateToView(Awards.ReleaseView)}>View Awards</Neo.Button>
                                    </div>
                                </div>}

                            {!this.viewModel.nextAwardToReleaseDate &&
                                <p>There are currently no awards ready for you to release.</p>}
                        </div>
                    </Neo.Loader>
                    {(upcomingExpiries?.length ?? 0) > 0 &&
                        <Neo.Loader task={this.viewModel.approvalsTaskRunner}>
                            <div>
                                <h2>Expiring Awards</h2>

                                <div>
                                    <p>
                                        <span className={this.viewModel.getActionDateClass(new Date())}>
                                            <i className="far fa-bell mr-2" />ACTION NEEDED: Awards expiring soon.
                                        </span>
                                        {upcomingExpiries!.map(c => <div className="text-highlight">{`${c.awardName} expiring on ${c.expiryDate.toDateString()}`}</div>)}
                                    </p>
                                    <div className="mt-3">
                                        <Neo.Button className="btn-150" onClick={() => this.navigation.navigateToView(Transactions.PortfolioView)}>Portfolio Details</Neo.Button>
                                    </div>
                                </div>
                            </div>
                        </Neo.Loader>}
                { isAlt = !isAlt }
                </PageBlock>
                <PageBlock alt={isAlt} alignment="end">
                    <div>
                        <h2>My Portfolio Overview</h2>
                        <p>
                            An illustration of your current potential profit of vested awards, as well as awards that have not yet vested.
                        </p>
                        <Neo.Loader show={this.viewModel.portfolioTaskRunner.isBusy || this.viewModel.portfolioService.exchangeRatesTask.isBusy}>
                            {this.viewModel.portfolioService.hasData &&
                                <div>
                                    <div className="flex-container flex-v-center mt-2">
                                        <Neo.DropDown className="highlight" style={{ width: 100 }} bind={this.viewModel.portfolioService.meta.currencyId} select={{ items: appData?.mainCurrencies }} />
                                        {this.viewModel.portfolioService.showMissingExchangeRateWarning &&
                                            <Neo.Alert className="alert-tiny ml-3" variant="warning" heading="Missing Exchange rates">
                                                Awards that cannot be converted will be shown in the award currency.
                                            </Neo.Alert>}
                                    </div>

                                    <div className="highlight-group mt-3">
                                        <label>Current vested potential profit: <InfoIcon text="Potential profit of your vested awards that are available to trade, at the current market price." /></label>
                                        {this.viewModel.portfolioService.vestedProfits.map(c => <div key={c.currencySymbol} className="text-highlight">{c.toString()}</div>)}
                                    </div>

                                    <div className="highlight-group mt-3">
                                        <label>Total potential profit: <InfoIcon text="Total potential profit of both vested and unvested awards, calculated at the current market price." /></label>
                                        {this.viewModel.portfolioService.totalProfits.map(c => <div key={c.currencySymbol} className="text-highlight">{c.toString()}</div>)}
                                    </div>

                                    <div className="mt-3">
                                        <Neo.Button className="btn-150" onClick={() => this.navigation.navigateToView(Transactions.PortfolioView)}>Portfolio Details</Neo.Button>
                                        <Neo.Button className="ml-3 btn-150" onClick={() => this.navigation.navigateToView(Transactions.PortfolioView)}>Trade awards</Neo.Button>
                                    </div>
                                </div>}
                        </Neo.Loader>
                    </div>
                    <div className="mb-n3 chart-align-right">
                        <div>
                            {chartOptions &&
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={this.getChartOptions()} />}
                        </div>
                    </div>
                { isAlt = !isAlt }
                </PageBlock>
                <PageBlock alt={isAlt}>
                    <div>
                        <h2>My Trade History</h2>
                        {this.viewModel.portfolioService.appData && this.viewModel.portfolioService.appData.portfolioIndicators!.lastTradeDate &&
                            <p>
                                <label>Last trade:</label><span className="text-highlight ml-1">{DateUtils.format(this.viewModel.portfolioService.appData.portfolioIndicators!.lastTradeDate, "dd MMM yyyy")}</span>
                            </p>}
                        <p>
                            Click here to view details of your trades.
                        </p>
                        <div className="mt-3">
                            <Neo.Button className="btn-150" onClick={() => this.navigation.navigateToView(Transactions.TradeHistoryView)}>Trade History</Neo.Button>
                        </div>

                    </div>
                { isAlt = !isAlt }
                </PageBlock>
            </div>
        )
    }

    private getChartOptions() {
        const data = this.viewModel.portfolioService.futureAmounts;
        const width = window.innerWidth > 1700 ? 600 : undefined;
        const colors = StyleUtils.getGraphColors();
        const symbol = this.viewModel.portfolioService.selectedCurrency?.symbol ?? "";

        if (data) {
            return {
                chart: { type: "column", height: 300, width: width, backgroundColor: "transparent" },
                title: { text: undefined },
                xAxis: { categories: ["Vested potential profit", `Potential profit ${data.yearPlus1Label}`, `Potential profit ${data.yearPlus2Label}`, `Potential profit ${data.yearPlus3Label}`, "Total potential profit"] },
                yAxis: { visible: false },
                series: [{
                    tooltip: { valueDecimals: 0, pointFormat: "<b>" + symbol + "{point.y}</b>" },
                    type: "column",
                    name: "Value",
                    data: [data.vestedValue, data.yearPlus1Value, data.yearPlus2Value, data.yearPlus3Value, data.allTimeValue],
                    colors: [colors[0], colors[1], colors[1], colors[1], colors[2]],
                    showInLegend: false,
                    colorByPoint: true,
                    groupPadding: 0.1,
                    dataLabels: [{ enabled: true, format: symbol + "{y:,.0f}", style: { fontSize: "10px", textOutline: false } },]
                }],
                credits: { enabled: false }
            } as Highcharts.Options;
        }
        return null;
    }
}