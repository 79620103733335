import React from 'react';
import { observer } from 'mobx-react';
import { Neo, Views } from '@singularsystems/neo-react';
import { HtmlView } from '@singularsystems/neo-notifications';
import { AppService, Types } from '../../../../../AwardsTypes';
import ParticipantOfferAwardDocumentLookup from '../../../../../../Common/Models/AwardDocuments/Queries/ParticipantOfferAwardDocumentLookup';
import { AwardDocumentDeliveryType } from '../../../../../../Common/Models/AwardDocuments/AwardDocumentDeliveryType.enum';
import FileDescriptor from '../../../../../../Common/Models/Files/FileDescriptor';
import { AwardDocumentAcceptanceType } from '../../../../../../Common/Models/AwardDocuments/AwardDocumentAcceptanceType.enum';
import { ITaskRunner, StringUtils } from '@singularsystems/neo-core';
import RespondToParticipantOfferAwardDocumentCommand from '../../../../../../Common/Models/AwardDocuments/Commands/RespondToParticipantOfferAwardDocumentCommand';
import { IReactionDisposer } from 'mobx';
import { LargeCheckBox } from '../../../../../../../App/Common/Components/Application/LargeCheckBox';
import SurveyCompletionVM from '../../../../Surveys/SurveyCompletionVM';
import SurveyParticipantDocumentLookup from '../../../../../../Common/Models/Surveys/Queries/SurveyParticipantDocumentLookup';

interface IAwardDocumentComponentProps {
    viewModel?:  AwardDocumentComponentVM | SurveyCompletionVM,
    document: ParticipantOfferAwardDocumentLookup | SurveyParticipantDocumentLookup;
    hideHeader?: boolean;
    alignAcceptTsAndCsLeft?: boolean;
    mainTaskRunner: ITaskRunner;
    readOnly: boolean;
    isAcceptedAwardSummary: boolean;
    documentCompleted: (document: ParticipantOfferAwardDocumentLookup | SurveyParticipantDocumentLookup) => void;
}

export class AwardDocumentComponentVM extends Views.ViewModelBase {

    public uploadFileDescriptor = new FileDescriptor();

    constructor(private mainTaskRunner: ITaskRunner,
        private documentCompleted: (document: ParticipantOfferAwardDocumentLookup | SurveyParticipantDocumentLookup) => void,
        public document: ParticipantOfferAwardDocumentLookup | SurveyParticipantDocumentLookup = new ParticipantOfferAwardDocumentLookup(),
        public isAcceptedAwardSummary: boolean = false,
        public fileUploadTask = AppService.get(Types.Neo.TaskRunner),
        public commandClient = AppService.get(Types.Awards.ApiClients.AwardDocumentCommandApiClient),
        private queryClient = AppService.get(Types.Awards.ApiClients.AwardDocumentsQueryApiClient)) {
        super(fileUploadTask);

        this.setup();

        this.makeObservable();
    }

    private acceptTermsAndConditionsReaction: IReactionDisposer | null = null;

    public disposeReactions() {
        if (this.acceptTermsAndConditionsReaction) {
            this.acceptTermsAndConditionsReaction();
        }
    }

    public setup() {
        if (this.document.acceptanceType === AwardDocumentAcceptanceType.AcceptTermsAndConditions) {
            // setup reaction
            this.disposeReactions();

            this.acceptTermsAndConditionsReaction = this.registerReaction(
                () => this.document.acceptedTermsAndConditions,
                acceptedTermsAndConditions => this.acceptTermsAndConditions(acceptedTermsAndConditions)
            );
        }
        else if (this.document.acceptanceType === AwardDocumentAcceptanceType.DocumentUpload) {
            if (!StringUtils.isNullOrWhitespace(this.document.uploadedFileName)) {
                // we already have an upload file, set to view knows we have one already
                this.uploadFileDescriptor.fileDescriptorId = "existing file";
            }
        }
    }

    public async acceptTermsAndConditions(acceptedTermsAndConditions: boolean | null) {
        await this.saveParticipantResponse(cmd => cmd.acceptedTermsAndConditions = acceptedTermsAndConditions);
    }

    public async confirmationDocumentUploaded() {
        this.document.uploadedFileName = this.uploadFileDescriptor.fileName;
        await this.saveParticipantResponse(cmd => cmd.uploadedFileDescriptor = this.uploadFileDescriptor);
    }

    private async saveParticipantResponse(commandBuilder: (command: RespondToParticipantOfferAwardDocumentCommand) => void) {
        const participantResponse = new RespondToParticipantOfferAwardDocumentCommand();

        if (this.document instanceof ParticipantOfferAwardDocumentLookup) {
            participantResponse.participantOfferAwardDocumentId = this.document.participantOfferAwardDocumentId;
        }

        if (this.document instanceof SurveyParticipantDocumentLookup) {
            participantResponse.surveyParticipantDocumentId = this.document.surveyParticipantDocumentId;
        }

        commandBuilder(participantResponse);

        await this.mainTaskRunner.run(async () => {
            this.commandClient.respondToParticipantOfferAwardDocument(participantResponse.toJSObject());
            this.document.respondedOn = new Date();

            if(this.document instanceof ParticipantOfferAwardDocumentLookup) {
                this.documentCompleted(this.document);
            }
        });
    }

    public async downloadDocument(document: ParticipantOfferAwardDocumentLookup | SurveyParticipantDocumentLookup) {
        if (document.downloadFileDescriptor) {
            document.downloadFileDescriptor.preparingDownload = true;
            let result;
            if (document instanceof ParticipantOfferAwardDocumentLookup) {
                result = await this.mainTaskRunner.waitFor(this.queryClient.getDownloadUrl(document));
            }
            else if (document instanceof SurveyParticipantDocumentLookup) {
                result = await this.mainTaskRunner.waitFor(this.queryClient.getSurveyDocumentDownloadUrl(document.surveyParticipantDocumentId, document.downloadFileDescriptor!.fileDescriptorId!));
            }

            if (result) {
                window.location.href = result.data;
                document.downloadFileDescriptor.preparingDownload = false;
            }
        }
    }
}

@observer
export default class AwardDocumentComponent extends React.Component<IAwardDocumentComponentProps> {

    public viewModel: AwardDocumentComponentVM;

    constructor(props: any) {
        super(props);
        this.viewModel = new AwardDocumentComponentVM(this.props.mainTaskRunner, this.props.documentCompleted, this.props.document, this.props.readOnly);
    }

    private getAcceptanceIcon(document: ParticipantOfferAwardDocumentLookup | SurveyParticipantDocumentLookup) {
        if (document.acceptanceType === AwardDocumentAcceptanceType.AcceptTermsAndConditions && document.acceptedTermsAndConditions === false) {
            return "far fa-times-circle text-danger";
        }
        else {
            return "far fa-check-circle";
        }
    }

    private supportsDragAndDrop() {
        // experimental, taken from here: https://stackoverflow.com/questions/6518033/how-to-detect-browser-support-file-api-drag-n-drop
        // Todo: need to consider Neo support
        const body = document.body
        return ("draggable" in body || ("ondragstart" in body && "ondrop" in body))
            && window.FormData && window.FileReader
    }

    public render() {
        const document = this.props.document;
        this.viewModel.document = document;

        return (
            <div className="award-document">
                {!this.props.hideHeader &&
                    <div className="award-document-header d-flex justify-content-between justify-content-center"
                        onClick={() => document.isExpanded = !document.isExpanded}>
                        <div className="header-text">
                            <i className={`collapse-icon ${document.isExpanded ? "expanded" : "collapsed"} fa fa-chevron-right`} />
                            <i className={`fa ${document.getIcon()} mr-3`}></i>{document.documentName}
                        </div>
                        <div>
                            <i className={`response-icon ${document.isCompleted() ? "active" : "pending"} ${this.getAcceptanceIcon(document)} fa-2x`} />
                        </div>
                    </div>}


                <div className={`award-document-content ${document.isExpanded ? "expanded" : "collapsed"} mb-2`}>
                    <Neo.Loader size="small" task={this.viewModel.taskRunner} className="mx-3">
                        {document.deliveryType === AwardDocumentDeliveryType.HtmlTemplate && document instanceof SurveyParticipantDocumentLookup &&
                            <HtmlView html={document.awardDocumentSetHtml} />}
                        {document.deliveryType === AwardDocumentDeliveryType.HtmlTemplate && document instanceof ParticipantOfferAwardDocumentLookup &&
                            <HtmlView html={document.acceptedAwardDocumentHTML ? document.acceptedAwardDocumentHTML : document.awardDocumentHTML} />
                        }

                        {document.deliveryType === AwardDocumentDeliveryType.DocumentDownload && document.downloadFileDescriptor &&
                            <div className="file-download mb-5">
                                <Neo.Loader size="small" show={document.downloadFileDescriptor.preparingDownload} >
                                    <Neo.Button variant="link"
                                        onClick={() => { this.viewModel.downloadDocument(document)}}>
                                        Download - {document.downloadFileDescriptor.fileName}
                                        <div className="float-right ml-2" >
                                            <i className="fa fa-download" />
                                        </div>
                                    </Neo.Button>
                                </Neo.Loader>
                            </div>}

                        <div className="award-document-response" >
                            {document.acceptanceType === AwardDocumentAcceptanceType.AcceptTermsAndConditions &&
                                <div className={`accept-terms-conditions ${(this.props.alignAcceptTsAndCsLeft ?? false) ? "" : `d-flex justify-content-between justify-content-center`}`}>
                                    {!this.props.alignAcceptTsAndCsLeft &&
                                        <div>
                                            {/* Left side */}
                                        </div>}
                                    <div className={(this.props.alignAcceptTsAndCsLeft ?? false) ? "d-flex" : `d-flex justify-content-center`}>
                                        <LargeCheckBox checked={document.meta.acceptedTermsAndConditions} readOnly={this.props.readOnly} />
                                        <label>I accept these terms and conditions</label>
                                    </div>
                                </div>}
                            {document.acceptanceType === AwardDocumentAcceptanceType.DocumentUpload && document.downloadFileDescriptor &&
                                <Neo.FileContext
                                    taskRunner={this.viewModel.fileUploadTask}
                                    bind={this.viewModel.uploadFileDescriptor.meta.fileDescriptorId}
                                    fileDescriptorProperty={this.viewModel.meta.uploadFileDescriptor}
                                    uploadEndpoint={
                                        document instanceof ParticipantOfferAwardDocumentLookup
                                            ? this.viewModel.commandClient.getParticipantDocumentUploadUrl(document.participantOfferAwardDocumentId, 0)
                                            : this.viewModel.commandClient.getParticipantDocumentUploadUrl(0, document.surveyParticipantDocumentId)
                                    }
                                    allowedExtensions={[".pdf", ".jpg", ".png"]}
                                    afterUpload={() => this.viewModel.confirmationDocumentUploaded()}>

                                    <div className="file-upload-info mb-4">
                                        <i className={StringUtils.isNullOrWhitespace(this.viewModel.document.uploadedFileName) || !document.respondedOn ?
                                            "far fa-hourglass text-primary" :
                                            "far fa-check-circle text-success"} />
                                        - {StringUtils.isNullOrWhitespace(this.viewModel.document.uploadedFileName) || !document.respondedOn ?
                                            "pending file upload" :
                                            `Response uploaded - ${this.viewModel.document.uploadedFileName}`}
                                    </div>

                                    {!this.props.readOnly &&
                                        <Neo.FileDropArea>
                                            <Neo.FileUploadButton variant="primary" className="mr-1" isOutline={!StringUtils.isNullOrWhitespace(this.viewModel.document.uploadedFileName)} >
                                                {this.viewModel.uploadFileDescriptor.fileDescriptorId ? "Change" : "Select"} response document
                                            </Neo.FileUploadButton>
                                            {(this.supportsDragAndDrop()) &&
                                                <span className="ml-1">or drop {this.viewModel.uploadFileDescriptor.fileDescriptorId ? "a new" : ""} file here.</span>}
                                            <Neo.FileUploadProgress variant={"info"} />
                                        </Neo.FileDropArea>}
                                </Neo.FileContext>}
                        </div>
                    </Neo.Loader>
                </div>
            </div >


        );
    }
}