import { AppServices, Routing } from '@singularsystems/neo-core';

import { Types } from './AwardsTypes';
import ApprovalsQueryApiClient from '../Common/ApiClients/Approvals/ApprovalsQueryApiClient';
import ApprovalsCommandApiClient from './ApiClients/Approvals/ApprovalsCommandApiClient';
import ParticipantOffersQueryApiClient from './ApiClients/ParticipantOffers/ParticipantOffersQueryApiClient';
import ParticipantAcceptanceCommandApiClient from './ApiClients/ParticipantOffers/ParticipantAcceptanceCommandApiClient';

import ApprovalsView from './Views/Approvals/ApprovalsView';
import ReleaseView from './Views/AwardRelease/ReleaseView';
import MyAwardsView from './Views/MyAwards/MyAwardsView';
import AcceptAwardsView from './Views/MyAwards/AcceptAwardsView';
import { CatalogueQueryApiClient } from '../Common/ApiClients/CatalogueQueryApiClient';
import AwardUtils from '../Common/Services/AwardUtils';
import AnswerQuestionnaireCommandApiClient from './ApiClients/Questionnaires/AnswerQuestionnaireCommandApiClient';
import AnswerQuestionnaireQueryApiClient from './ApiClients/Questionnaires/AnswerQuestionnaireQueryApiClient';
import AwardsDataCache from './Services/AwardsDataCache';
import OfferTotalsService from '../Common/Services/OfferTotalsService';
import IncentiveSchemesQueryApiClient from '../Common/ApiClients/IncentiveSchemesQueryApiClient';
import AwardDocumentsQueryApiClient from '../Common/ApiClients/AwardDocuments/AwardDocumentsQueryApiClient';
import AwardDocumentsCommandApiClient from '../Common/ApiClients/AwardDocuments/AwardDocumentsCommandApiClient';
import IncentiveGroupsQueryApiClient from '../Common/ApiClients/IncentiveGroupsQueryApiClient';
import AwardModuleConfigApiClient from '../Common/ApiClients/Config/AwardModuleConfigApiClient';
import MyAwardsApiClient from './ApiClients/ParticipantOffers/MyAwardsApiClient';
import SurveysApiClient from '../Common/ApiClients/SurveysApiClient';
import SurveyCompletionView from './Views/Surveys/SurveyCompletionView';

// Modules
const awardsAppModule = new AppServices.Module("Clients", container => {
    // Api Clients
    container.bind(Types.Awards.ApiClients.ApprovalsQueryApiClient).to(ApprovalsQueryApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.ApprovalsCommandApiClient).to(ApprovalsCommandApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.CatalogueQueryApiClient).to(CatalogueQueryApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.ParticipantOffersQueryApiClient).to(ParticipantOffersQueryApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.MyAwardsApiClient).to(MyAwardsApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.ParticipantAcceptanceCommandApiClient).to(ParticipantAcceptanceCommandApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.AnswerQuestionnaireQueryApiClient).to(AnswerQuestionnaireQueryApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.AnswerQuestionnaireCommandApiClient).to(AnswerQuestionnaireCommandApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.IncentiveSchemesQueryApiClient).to(IncentiveSchemesQueryApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.IncentiveGroupsQueryApiClient).to(IncentiveGroupsQueryApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.AwardDocumentsQueryApiClient).to(AwardDocumentsQueryApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.AwardDocumentCommandApiClient).to(AwardDocumentsCommandApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.AwardModuleConfigApiClient).to(AwardModuleConfigApiClient).inSingletonScope();
    container.bind(Types.Awards.ApiClients.SurveysApiClient).to(SurveysApiClient).inSingletonScope();

    // Services
    container.bind(Types.Awards.Services.AwardUtils).to(AwardUtils).inSingletonScope();
    container.bind(Types.Awards.Services.OfferTotalsService).to(OfferTotalsService).inSingletonScope();
    container.bind(Types.Awards.Services.AwardsDataCache).to(AwardsDataCache).inSingletonScope();    
});

// Test 
const awardsTestModule = new AppServices.Module("Clients", container => {
    // bind test types
});

// Routes
const ApprovalsRoute: Routing.IMenuRoute =
    { name: "Approvals", path: "/approvals", component: ApprovalsView }

const ReleaseRoute: Routing.IMenuRoute =
    { name: "Release", path: "/release", component: ReleaseView }

const AcceptAwardsRoute: Routing.IMenuRoute =
    { name: "My Pending Awards", path: "/my-awards/accept", component: AcceptAwardsView }

const MyAwardsRoute: Routing.IMenuRoute =
    { name: "My Awards", path: "/my-awards", component: MyAwardsView, exact: true, routeChildren: [AcceptAwardsRoute] }

const SurveyRoute: Routing.IMenuRoute =
    { name: "Surveys", path: "/complete-survey", component: SurveyCompletionView, exact: true }

const AwardsPureRoutes = [ApprovalsRoute, AcceptAwardsRoute, SurveyRoute];

export {
    awardsAppModule,
    awardsTestModule,
    AwardsPureRoutes,
    ApprovalsRoute,
    ReleaseRoute,
    MyAwardsRoute
}