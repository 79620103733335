import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../AwardsTypes';
import { Types as CommonAwardTypes }  from '../../../../Awards/Common/CommonAwardsTypes';
import SurveyParticipantDocumentLookup from '../../../Common/Models/Surveys/Queries/SurveyParticipantDocumentLookup';
import SurveyParticipantLookup from '../../../Common/Models/Surveys/Queries/SurveyParticipantLookup';
import { AwardDocumentComponentVM } from '../Acceptance/Components/Steps/Documents/AwardDocumentComponent';
import ParticipantOfferAwardDocumentLookup from '../../../Common/Models/AwardDocuments/Queries/ParticipantOfferAwardDocumentLookup';
import { AwardDocumentAcceptanceType } from '../../../Common/Models/AwardDocuments/AwardDocumentAcceptanceType.enum';
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration';

export enum SurveyViewMode {
    Survey = "survey",
    Document = "document",
    Questionnaire = "questionnaire"
}

interface ISurveyStep { mode: SurveyViewMode, surveyParticipantDocumentId?: number }
interface ILoadRequest { mode: SurveyViewMode, surveyParticipantDocumentId?: number }

@NeoModel
export default class SurveyCompletionVM extends Views.ViewModelBase {

    public documentComponentVM: AwardDocumentComponentVM;

    constructor(taskRunner = AppService.get(Types.Neo.TaskRunner),
        private surveysApiClient = AppService.get(CommonAwardTypes.ApiClients.SurveysApiClient),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) 
    {
        super(taskRunner);
        this.documentComponentVM = new AwardDocumentComponentVM(
            this.taskRunner,
            this.documentCompleted.bind(this) as (document: ParticipantOfferAwardDocumentLookup | SurveyParticipantDocumentLookup) => void
        );
    }

    public stepIndex = 0;
    public surveySteps: ISurveyStep[] = [];
    public viewMode = SurveyViewMode.Survey;
    public surveyParticipantDocumentLookup = new SurveyParticipantDocumentLookup();
    public surveyParticipantLookup = new SurveyParticipantLookup();
    public isLastDocument = false;

    public async initialise() {
         this.stepIndex = 0;
    }

    private documentCompleted(document: SurveyParticipantDocumentLookup) {
        this.surveyParticipantDocumentLookup.acceptedTermsAndConditions = document.acceptedTermsAndConditions;
        this.surveyParticipantDocumentLookup.respondedOn = new Date();
        this.notifications.addSuccess("Response saved", null, NotificationDuration.Standard);
        this.onDocumentCompleted(document);
    }

    private onDocumentCompleted(document: SurveyParticipantDocumentLookup) {
        const surveyDoc = this.surveyParticipantLookup.documents.find(c => c.surveyParticipantDocumentId === document.surveyParticipantDocumentId);
        if (surveyDoc) {
            surveyDoc.respondedOn = document.respondedOn;
            surveyDoc.uploadedFileName = document.uploadedFileName;
        }
    }
    
    public async loadSurveyData(surveyParticipantId: number = 0) {
        await this.taskRunner.run(async () => {
            await this.loadSurveyParticipantData(surveyParticipantId);
        });

        this.startSurvey();
    }
    
    public async loadSurveyParticipantDocument(surveyParticipantId: number = 0, surveyParticipantDocumentId: number = 0) { 
        this.documentComponentVM.disposeReactions();

        const result = await this.surveysApiClient.getSurveyParticipantDocumentLookup(surveyParticipantId, surveyParticipantDocumentId);
        this.surveyParticipantDocumentLookup.set(result.data);
     
        if (this.surveyParticipantDocumentLookup.firstViewedOn == null) {
            await this.surveysApiClient.updateSurveyParticipantDates(surveyParticipantId, true, false);
        }      
    }
    
    public async loadSurveyParticipantData(surveyParticipantId: number) {
        const result = await this.surveysApiClient.getSurveyParticipantsLookupAsync(surveyParticipantId);
        this.surveyParticipantLookup = SurveyParticipantLookup.fromJSObject<SurveyParticipantLookup>(result.data);   
    }

    private startSurvey() {
        this.setupSurveySteps();
        this.stepIndex = 0;
        this.loadSurveySteps();
    }

    private setupSurveySteps() {
        const surveySteps: ISurveyStep[] = [];
        surveySteps.push({ mode: SurveyViewMode.Survey });

        for (const surveyParticipantDocumentLookup of this.surveyParticipantLookup.documents) {
            surveySteps.push({ mode: SurveyViewMode.Document, surveyParticipantDocumentId: surveyParticipantDocumentLookup.surveyParticipantDocumentId });
        }

        this.surveySteps = surveySteps;
    }

    public async next() {
         if (await this.canLeaveStep()) {
             this.stepIndex++;
             await this.loadSurveySteps();
         }
    }

    public async completeSurvey() {
        if (await this.canLeaveStep()) {
            await this.surveysApiClient.updateSurveyParticipantDates(this.surveyParticipantLookup.surveyParticipantId, false, true);
            this.notifications.addInfo("Thank you for completing the task.", null, NotificationDuration.Standard);
         }
    }

    public async back() { 
        this.stepIndex--;
        await this.loadSurveySteps();
        this.isLastDocument = false;
    }

    private async canLeaveStep() {
        if (this.viewMode === SurveyViewMode.Document) {
            if (!this.documentComponentVM.document.isCompleted) {
                if (this.documentComponentVM.document.acceptanceType === AwardDocumentAcceptanceType.AcceptTermsAndConditions) {
                    this.notifications.addWarning("Please accept the terms and conditions", null, NotificationDuration.Standard);
                } else {
                    this.notifications.addWarning("Please upload required document", null, NotificationDuration.Standard);
                }
                return false;
            }
        }

        return true;

    }

    private async loadSurveySteps() {
        const step = this.surveySteps[this.stepIndex];
        await this.loadSurveyViewMode({ mode: step.mode, surveyParticipantDocumentId: step.surveyParticipantDocumentId });
    }

    private async loadSurveyViewMode(request: ILoadRequest) {
        if ( request.mode === SurveyViewMode.Survey) {
            this.viewMode = request.mode;
            this.loadSurveyParticipantDocument(this.surveyParticipantLookup.surveyParticipantId);
            return true;
        }
        else if (request.mode === SurveyViewMode.Document) {
            if (this.stepIndex === this.surveySteps.filter(x => x.mode == SurveyViewMode.Document).length)
            {
                this.isLastDocument = true;
            }

            const document = await this.loadSurveyDocument(this.surveyParticipantLookup, request);

            if (document) {
                this.surveyParticipantDocumentLookup = document;
                this.viewMode = request.mode;
                return true;
            }      
        }
        else if (request.mode === SurveyViewMode.Questionnaire) {
            this.viewMode = request.mode;
            return true;
        }
         return false;
    }

    public async loadSurveyDocument(survey: SurveyParticipantLookup, request: ILoadRequest) {
        if (survey.documents && survey.documents.length > 0) {
            let surveyDocument: SurveyParticipantDocumentLookup | null = null;
            if (request.surveyParticipantDocumentId) {
                surveyDocument = survey.documents.find(c => c.surveyParticipantDocumentId === request.surveyParticipantDocumentId) ?? null;
                if (surveyDocument) {
                    const result = await this.surveysApiClient.getSurveyParticipantDocumentLookup(0, surveyDocument.surveyParticipantDocumentId);
                    const document = SurveyParticipantDocumentLookup.fromJSObject<SurveyParticipantDocumentLookup>(result.data);
                    return this.setupDocument(document);
                }
            }
        }
        return null;
    }

    private setupDocument(document: SurveyParticipantDocumentLookup) {
        if (document) {
            this.documentComponentVM.disposeReactions();
            this.documentComponentVM.document = document;
            this.documentComponentVM.setup();
        }

        return document;
    }
}