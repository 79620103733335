import { Attributes, NeoModel } from '@singularsystems/neo-core';
import BrokerAccountLookup from '../../Brokers/Lookups/BrokerAccountLookup';
import RatesLookup from '../../RatesLookup';
import { CalculationBase, ICalculationBase } from './CalculationBase';
import { TradeType } from '../../Trading/TradeType';
import IncentiveSchemeParticipantLookup from '../../IncentiveSchemeParticipantLookup';
import { TrancheLink } from './TrancheLink';
import { Awards } from '../../../../../App';
import { SellToBuyState } from './CalculationGroup';

@NeoModel
export default class CalculationTranche extends CalculationBase implements ICalculationBase {

    // Child objects

    @Attributes.NoTracking()
    public brokerAccount!: BrokerAccountLookup;

    @Attributes.NoTracking()
    public rates!: RatesLookup;

    @Attributes.NoTracking()
    public transactionsScheme: IncentiveSchemeParticipantLookup | null = null;

    @Attributes.NoTracking()
    public trancheLink: TrancheLink | null = null;

    public linkedTranches: CalculationTranche[] = [];

    protected getLinkedRecords() {
        return this.linkedTranches;
    }

    // Properties

    @Attributes.NoTracking()
    private _sellQuantity = 0;

    @Attributes.Integer()
    public get sellQuantity() {
        return this._sellQuantity;
    }
    public set sellQuantity(value: number) {
        this.setSellQuantity(value);
        this.trancheLink?.setQuantityOnLinkedTranches(this);
    }

    @Attributes.NoTracking()
    private _buyQuantity = 0;

    @Attributes.Integer()
    public get buyQuantity() {
        return this._buyQuantity;
    }
    public set buyQuantity(value: number) {
        this.setBuyQuantity(value);
        this.trancheLink?.setQuantityOnLinkedTranches(this);
    }

    private _limitPrice: number | null = null;

    @Attributes.NoTracking()
    @Attributes.Integer()
    public get limitPrice() {
        return this._limitPrice;
    }
    public set limitPrice(value: number | null) {
        this._limitPrice = value;
        if (this.trancheLink && !this.trancheLink.suppressSynchronisation) {
            for (let linked of this.linkedTranches) {
                if (linked.instrument === this.instrument) {
                    linked._limitPrice = value;
                }
            }
        }
    }

    public setSellQuantity(value: number) {
        value = Math.max(0, Math.min(this.availableBalance, value));
        this._sellQuantity = value;

        if (value + this._buyQuantity > this.availableBalance) {
            this._buyQuantity = this.availableBalance - value;
        }
    }

    public setBuyQuantity(value: number) {
        value = Math.max(0, Math.min(this.availableBalance, value));
        this._buyQuantity = value;

        if (value + this._sellQuantity > this.availableBalance) {
            this._sellQuantity = this.availableBalance - value;
        }
    }

    public get isSellToBuy(): boolean {
        return this.trancheBalance.incentiveScheme.allowSellToCover && this.lastTradeType === TradeType.SellToCover;
    }

    /** True if trading these shares will cover the debt. If not, these shares will not be included in a trade group. */
    public get hasProfit() {
        if (this.trancheLink) {
            return this.trancheLink.hasProfit;
        } else {
            return ((this.availableBalance * this.effectiveSellPrice) + this.remainingAwardDebt + this.tradingCosts) > 0
        }
    }

    public get canTrade() {
        return this.isTradeable && this.hasQuantity;
    }

    public get vestingDate() {
        return this.trancheBalance.vestingDate;
    }

    public get releaseDate() {
        return this.trancheBalance.releaseDate;
    }

    public get hasBeenReleased(){
        return this.trancheBalance.hasBeenReleased;
    }

    /**
     * Is the tranche vested, or past the trade open date.
     */
    public get isVested() {
        return this.trancheBalance.isVested;
    }

    public get hasAcceleration() {
        return this.trancheBalance.acceleratedVestingDate !== null;
    }

    /** 
     * Is the tranche vested, and has no reasons why the participant cannot trade.
     */
    public get isTradeable(): boolean {

        if (this.cannotTradeReason) {
            return false;
        }

        return true;
    }

    public get selfCannotTradeReason() {

        const trancheReason = this.trancheBalance.cannotTradeReason;

        if (trancheReason) {
            return trancheReason;
        }

        if (this.trancheBalance.incentiveScheme.allowedTradeType === Awards.AllowedTradeType.AllOrNone && this.unitsAfterTrade > 0) {
            return "You must trade all units for this scheme.";
        }
        if (this.trancheBalance.incentiveScheme.allowedSellPriceType === Awards.AllowedExercisePriceType.NoLimitAllowed && this.limitPrice !== null) {
            return "This scheme does not allow limit prices. Trades must be placed at market price.";
        }
        if (!this.hasProfit) {
            return "This trade will make a loss.";
        }
        if (this.transactionsScheme?.mustPreventTrade) {
            return "You need to provide documents for documentation requirement: " + this.transactionsScheme.missingDocumentText;
        }
        return "";
    }

    public get cannotTradeReason() {

        const selfCannotTradeReason = this.selfCannotTradeReason;

        if (selfCannotTradeReason) {
            return selfCannotTradeReason;
        }

        if (this.linkedTranches.some(c => !!c.selfCannotTradeReason)) {
            return "Cannot trade because related tranches cannot be traded."
        }

        return "";
    }

    // Trade calculations

    public get availableBalance() {
        return this.trancheBalance.availableBalance;
    }

    public get remainingAwardDebt() {
        return this.trancheBalance.remainingAwardDebt;
    }

    @Attributes.Float()
    public get effectiveSellPrice() {
        if (this.limitPrice === null) {
            return this.trancheBalance.customInstrumentPrice;
        } else {
            return this.limitPrice;
        }
    }

    public get tradeProceeds() {
        return this.sellQuantity * this.effectiveSellPrice / (this.trackingInstrument ? this.trancheBalance.awardPrice : 1);
    }

    /**
     * Award debt for the quantity being traded on the screen.
     */
    public get proportionalAwardDebt() {
        return -this.trancheBalance.debtPerUnit * (this.buyQuantity + this.sellQuantity);
    }

    /**
     * Pre-Tax amount for the quantity being traded on the screen.
     */
    public get proportionalTaxAmount() {
        return (this.trancheBalance.awardTax ?? 0) / this.trancheBalance.unitBalance * (this.buyQuantity + this.sellQuantity);
    }

    public get calcTaxAmount() {
        if (this.trancheBalance.awardTax !== null) {
            return this.proportionalTaxAmount;
        } else {

            const settings = this.trancheBalance.settings,
                taxRate = this.trancheBalance.customTaxRate ?? this.trancheBalance.taxRate,
                costPerUnit = this.trancheBalance.costPerUnit;

            const brokerageForTaxCalc = settings.includeBrokerageInTaxDirective ? this.saleBrokerage : 0;
            const sellTax = -(((this.tradeProceeds - (this.sellQuantity * costPerUnit)) + brokerageForTaxCalc) * taxRate);
            const buyTax = -((this.trancheBalance.customInstrumentPrice - costPerUnit) * this.buyQuantity) * taxRate;

            if (this.trancheBalance.awardLinkId !== null || sellTax + buyTax < 0) {
                return sellTax + buyTax;
            } else {
                return 0;
            }
        }
    }

    public calcSellToBuy(state?: SellToBuyState, suppressTrancheSynchronisation = true) {

        if (this.effectiveSellPrice > 0) {

            if (this.trancheLink && suppressTrancheSynchronisation) {
                this.trancheLink.suppressSynchronisation = true;
            }

            const instrumentPrice = this.trancheBalance.customInstrumentPrice,
                costPerUnit = this.trancheBalance.costPerUnit,
                taxRate = this.trancheBalance.customTaxRate ?? this.trancheBalance.taxRate,
                debtOffset = state?.remainingLossToCover ?? 0;

            const safePrice = this.limitPrice ?? (instrumentPrice * (1 - this.trancheBalance.incentiveScheme.sellToCoverBufferPriceReductionPercentage));
            const gainPrice = safePrice - costPerUnit;
            const awardDebtPositive = (-this.remainingAwardDebt) + debtOffset;
            const hasKnownTaxAmount = this.trancheBalance.awardTax !== null;

            // Guess how many units to sell based on the debt + tax + brokerage.
            let sellQty = awardDebtPositive / safePrice;
            const taxAmount = hasKnownTaxAmount ? -this.proportionalTaxAmount : (sellQty * gainPrice * taxRate);
            const brokerageIncl = (state !== undefined ? state.feePercent * (awardDebtPositive + taxAmount) : this.brokerAccount.getBrokingFeeTotal(awardDebtPositive + taxAmount, true));
            sellQty = Math.min(this.availableBalance, (awardDebtPositive + taxAmount + brokerageIncl) / safePrice);

            // Work out how many more are needed to sell based on the buy costs.
            let buyQty = this.availableBalance - sellQty,
                buyCosts = this.brokerAccount.getBrokingFeeTotal(buyQty * instrumentPrice, false);
            if (!hasKnownTaxAmount) {
                buyCosts += (buyQty * (instrumentPrice - costPerUnit) * taxRate)
            }
            sellQty += Math.ceil(buyCosts / safePrice);

            this.sellQuantity = Math.ceil(sellQty);
            this.buyQuantity = this.availableBalance - this.sellQuantity;

            // Large adjustment
            let proceeds = this.netProceeds;
            if (Math.abs(proceeds - debtOffset) >= safePrice) {
                let adjust = Math.floor(-(proceeds - debtOffset) / safePrice);
                if (adjust > 0) {
                    this.sellQuantity += adjust;
                } else {
                    // This is subtracting a negative amount, so effectively adding to buy quantity.
                    // Sell qty is automatically reduced in the setter to keep the total the same.
                    this.buyQuantity -= adjust;
                }
            }

            // Bad data may cause an infinite loop.
            let iterations = 0;

            // Adjust one by one.
            while (this.netProceeds - debtOffset > safePrice && this.sellQuantity > 0 && iterations < 10) {
                this.buyQuantity += 1;
                iterations++;
            }
            while (this.netProceeds - debtOffset < 0 && this.buyQuantity > 0 && iterations < 10) {
                this.sellQuantity += 1;
                iterations++
            }

            if (this.trancheLink && suppressTrancheSynchronisation) {
                this.linkedTranches.forEach(c => c.calcSellToBuy(undefined, false));
                this.trancheLink.suppressSynchronisation = false;
            }

            if (state && state.remainingLossToCover > 0) {
                if (this.netProceeds > 0) {
                    state.remainingLossToCover -= Math.min(state.remainingLossToCover, this.netProceeds);
                }
            }
        }
    }
}