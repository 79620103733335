import { AppServices } from '@singularsystems/neo-core';

import { ICatalogueQueryApiClient } from './ApiClients/CatalogueQueryApiClient';
import { IApprovalsQueryApiClient } from './ApiClients/Approvals/ApprovalsQueryApiClient';
import { IIncentiveSchemesQueryApiClient } from './ApiClients/IncentiveSchemesQueryApiClient';
import { IIncentiveGroupsQueryApiClient } from './ApiClients/IncentiveGroupsQueryApiClient';
import { IAwardModuleConfigApiClient } from './ApiClients/Config/AwardModuleConfigApiClient';
import AwardUtils from './Services/AwardUtils';
import OfferTotalsService from './Services/OfferTotalsService';
import { ISurveysApiClient } from './ApiClients/SurveysApiClient';

// Symbols to expose outside of this module
const CommonTypes = {
    ApiClients: {
        ApprovalsQueryApiClient: new AppServices.ServiceIdentifier<IApprovalsQueryApiClient>("Awards.ApiClients.ApprovalsQueryApiClient"),
        CatalogueQueryApiClient: new AppServices.ServiceIdentifier<ICatalogueQueryApiClient>("Awards.ApiClients.CatalogueQueryApiClient"),
        IncentiveSchemesQueryApiClient: new AppServices.ServiceIdentifier<IIncentiveSchemesQueryApiClient>("Awards.ApiClients.IncentiveSchemesQueryApiClient"),
        IncentiveGroupsQueryApiClient: new AppServices.ServiceIdentifier<IIncentiveGroupsQueryApiClient>("Awards.ApiClients.IncentiveGroupsQueryApiClient"),
        AwardModuleConfigApiClient: new AppServices.ServiceIdentifier<IAwardModuleConfigApiClient>("Awards.ApiClients.AwardModuleConfigApiClient"),
        SurveysApiClient: new AppServices.ServiceIdentifier<ISurveysApiClient>("Awards.ApiClients.SurveysApiClient"),
    },
    Services: {
        OfferTotalsService: new AppServices.ServiceIdentifier<OfferTotalsService>("Awards.Services.OfferTotalsService"),
        AwardUtils: new AppServices.ServiceIdentifier<AwardUtils>("Awards.Services.AwardUtils")
    },
}

export { CommonTypes as Types }